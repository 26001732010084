<template>
  <div class="t-submenu" :class="{'t-transition':transition,'t-submenu-show':(isActive||isShow)}" :style="{maxHeight:isShow?childrenHeight+'rem':'56rem'}">
    <div class="t-submenu-title" @click="onChange">
      <slot name="title"></slot>
<!--      <span class="t-icon">
         <i class="el-icon-arrow-up "></i>
      </span>-->
      <span class="t-icon">
        <i v-if="!isShow" class="el-icon-arrow-down"></i>
        <i v-else class="el-icon-arrow-up"></i>
      </span>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "SubMenu",
  inject:['rootMenu'],
  provide(){
    return {
      subMenu:this
    }
  },
  props:{
    index:String,
  },
  data(){
    return{
      isShow:false,
      transition:false,
      childrenHeight:3000,
      isActive:false
    }
  },
  mounted() {
    this.eachRouter();
  },
  watch:{
    "$route.matched"(n,o){
      if (n[1].path !== o[1].path)
        this.eachRouter();
    }
  },
  methods:{
    onChange(){
      let root = this.rootMenu;
      let slots = root.$slots.default;
      let id = this._uid;
      if (root.uniqueOpened){
        slots.forEach(item=>{
          let cid = item.componentInstance._uid;
          if (cid !== id) {
            item.componentInstance.isShow = false;
          }
          else{
            this.transition = true;
            this.isShow = !this.isShow;
          }

        })
      }
    },
    eachRouter(){
      let matched = this.$route.matched;
      for (let i = 2,len = matched.length;i<len;i++){
        let item = matched[i];
        if (item.path === this.index) {
          this.transition = true;
          this.isShow = true;
          this.isActive = true;
          this.rootMenu.subMenu = this;
          break;
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/_setting.scss";
.t-submenu{
  font-weight: bold;
  overflow: hidden;
  .t-submenu-title{
    padding-left: 20rem;
    height: 56rem;
    line-height: 56rem;
    cursor: pointer;
    .t-icon{
      float: right;
      margin-right: 20rem;
      >i{
        font-weight: 600;
      }

    }
  }
  .t-menu-item{
    font-weight: 100;
  }
  .t-menu-item-active{
    color: $menuItem-active-color;
    border-bottom: none;
    background-color: $menuItem-active-bg;
    //border-right: 4rem solid $primary;
  }
}
.t-transition{
  transition: 200ms all linear;
}
.t-submenu-show{
  color:$primary;
}
</style>
