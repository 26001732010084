<template>
  <div class="right-header">
    <div class="top-menu">
      <t-menu :current-active="$route.matched[1].path" mode="horizontal" router style="height: 100%;padding-top: 10rem;">
        <t-menu-item v-for="(item,index) in menuConfig" :key="item.web_route" :index="item.web_route" active-style="color:#1C4EFD;border-bottom:4rem solid #1C4EFD;font-weight:700 !important;" style="height:100%;margin:0 10rem;font-size: 20rem;" @click="$store.commit('setRightMenuIndex',index)">{{ item.name }}</t-menu-item>
      </t-menu>
    </div>

    <el-dropdown ref="dropdown" :hide-on-click="false" placement="bottom-start" trigger="click" @command="onDropDown">
      <div class="el-dropdown-link" style="display: flex; align-items: center; height: 100%; cursor: pointer;user-select: none;">
        <div class="user">
          <img :src="user_info.avator === '' ? imgurl : user_info.avator" alt="头像" class="avatar"/>
          <div class="info">
            <div class="name">{{ user_info.name }}</div>
            <div class="role">{{ user_info.role_list[0].name }}</div>
          </div>
        </div>
      </div>
      <el-dropdown-menu slot="dropdown" style="width: 140rem; text-align: center;">
        <el-dropdown-item v-if="!showRoleArr.includes(user_info.role_type)" :command="0">个人资料</el-dropdown-item>
        <el-dropdown-item :command="6" divided>绑定邮箱</el-dropdown-item>
        <!--        <el-dropdown-item :command="1" :divided="!showRoleArr.includes(user_info.role_type)">切换角色</el-dropdown-item>-->
        <template v-if="!showRoleArr.includes(user_info.role_type)">
          <el-dropdown-item :command="4" divided>问题反馈</el-dropdown-item>
          <el-dropdown-item :command="5" divided>账号设置</el-dropdown-item>
          <!--					<el-dropdown-item divided>-->
          <!--						<el-dropdown class="personalization" placement="right-start" trigger="click">-->
          <!--							<div class="el-dropdown-link personalization">账号设置</div>-->
          <!--							<el-dropdown-menu slot="dropdown">-->
          <!--								<el-dropdown-item @click.native="handleClickDropDown(0)">修改密码</el-dropdown-item>-->
          <!--								<el-dropdown-item @click.native="handleClickDropDown(1)">账号设置</el-dropdown-item>-->
          <!--							</el-dropdown-menu>-->
          <!--						</el-dropdown>-->
          <!--					</el-dropdown-item>-->
        </template>
        <el-dropdown-item v-else :command="3">修改密码</el-dropdown-item>
        <el-dropdown-item :command="2" divided>退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <el-dialog :append-to-body="true" :visible.sync="dialogVisible" title="" width="30%">
      <div class="roleTab">
        <div class="current">当前角色为: {{ user_info.role_name }}</div>
        <div class="tabRole">
          <p v-for="(item, index) in userInfo.user_role" :key="'role' + index" class="role-radio">
            <el-radio v-model="radioRole" :label="item.id"> {{ item.name }}</el-radio>
          </p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button :loading="loadingState" type="primary" @click="toggleRole">确定切换</el-button>
			</span>
    </el-dialog>
    <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="modifyPasswordVisible" title="修改密码" width="350rem">
      <div class="modifyPassword">
        <el-form label-width="80rem">
          <el-form-item :model="form" label="原密码">
            <el-input v-model="form.oldPassword" placeholder="请输入原密码" style="width: 100% !important"></el-input>
          </el-form-item>
          <el-form-item :model="form" label="新密码">
            <el-input v-model="form.password" placeholder="请输入新的密码" style="width: 100% !important"></el-input>
          </el-form-item>
          <el-form-item label="确认密码">
            <el-input v-model="form.confirmPassword" placeholder="再次输入新密码" style="width: 100% !important"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
				<el-button @click="modifyPasswordVisible = false">取 消</el-button>
				<el-button :loading="loadingState" type="primary" @click="modifyPassword">确认修改</el-button>
			</span>
    </el-dialog>
    <!-- 问题反馈 -->
    <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="feedbackShow" title="我要反馈" width="350rem">
      <div class="modifyPassword">
        <el-form ref="feedback" :model="feedbackForm" :rules="feedbackRules" label-width="80rem">
          <el-form-item label="问题分类" prop="type">
            <el-select v-model="feedbackForm.type" :popper-append-to-body="false" class="feedback_type" placeholder="请选择">
              <el-option
                  v-for="item in feedbackTpeOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="情况说明" prop="content">
            <el-input
                v-model.trim="feedbackForm.content"
                :autosize="{ minRows: 4, maxRows: 6}"
                maxlength="2000"
                placeholder="请填写你的问题和意见，感谢你的支持！"
                show-word-limit
                type="textarea"></el-input>
          </el-form-item>
          <el-form-item label="上传图片">
            <el-upload
                ref="upload"
                :before-upload="imgUploadBefore"
                :class="isUploaded ? 'is-uploaded' : ''"
                :headers="headers"
                :http-request="uploadImg"
                :limit="6"
                :on-exceed="handleExceed"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                accept="image/png, image/jpeg, image/jpg"
                action="http://www.baidu.com"
                list-type="picture-card"
                multiple
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :append-to-body="true" :visible.sync="uploadDialogShow">
              <img :src="dialogImageUrl" alt="" width="100%">
            </el-dialog>
            <p style="color: #999">（请上传6张及以内小于5M的png/jpg格式的图片）</p>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
				<el-button :loading="loadingState" type="primary" @click="feedbackSubmit">提交</el-button>
			</span>
    </el-dialog>

    <!-- 问题反馈成功后弹框 -->
    <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="feedbackSuccessShow" width="450rem">
      <div style="padding: 50rem 0 40rem;text-align: center">
        <img alt="" src="../../assets/img/success.png" style="margin-bottom: 45rem">
        <p style="margin-bottom: 15rem">你反馈的关于鸿鹄教育信息化系统的建议和问题，已经提交成功！</p>
        <p style="margin-bottom: 60rem">感谢你的反馈，我们收到后会尽快解决这些问题。</p>
        <el-button type="primary" @click="feedbackSuccessShow = false">返回</el-button>
      </div>
    </el-dialog>

    <!--  绑定邮箱  -->
    <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="MailboxDialog" title="绑定邮箱" width="30%">
      <el-form label-position="left" label-width="120px">
        <el-form-item label="我的账号">
          {{ USER_INFO.userinfo.username }}
        </el-form-item>
        <el-form-item label="企业邮箱">
          <el-input v-model="USER_INFO.userinfo.mailbox" maxlength="100" placeholder="请输入邮箱"></el-input>
        </el-form-item>
      </el-form>
      <div style="display: flex;justify-content: center">
        <el-button style="margin-right: 32px" type="primary" @click="onMailboxSave">保存</el-button>
        <el-button @click="MailboxDialog = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import img from '../../assets/img/avatar_boy.png'
import config from '../../router/config'
import tools from "../../utils/tools";
import {postApi2} from "@/api";

export default {
  data() {
    return {
      headers: {
        'authorization': 'Bearer ' + sessionStorage.getItem('TOKEN')
      },
      formLabelAlign: {},
      form: {
        oldPassword: '',
        password: '',
        confirmPassword: ''
      },
      imgurl: img,
      dialogVisible: false,
      MailboxDialog: false,
      currentRole: '语文1-2-3授课教师',
      radioRole: null,
      loadingState: false,
      modifyPasswordVisible: false, // 修改密码弹窗显示
      feedbackShow: false,
      feedbackSuccessShow: false,
      feedbackTpeOptions: [],
      feedbackRules: {
        type: [{required: true, message: '请选择反馈类型'}],
        content: [{required: true, message: '请填写情况说明'}],
      },
      feedbackForm: {
        type: '',
        content: '',
        image: ''
      },

      uploadDialogShow: false,
      dialogImageUrl: '',
      isUploaded: false,  //是否传满6张图片
      listImage: [],
      showRoleArr: [0, 3, 4, 5, 6],
      USER_INFO: {
        userinfo: {
          mailbox: ''
        }
      }
    }
  },

  computed: {
    ...mapGetters(['userInfo', 'menuConfig']),
    user_info() {
      return this.userInfo.userinfo
    }
  },
  watch: {
    feedbackShow(val) {
      if (!val) {
        this.clearDialog()
      }
    }
  },
  mounted() {
    this.activeIndex = this.$route.path
    this.$forceUpdate()

    this.getFeedbackConfig()

    this.getConfig()
  },
  methods: {
    log: console.log,
    ...mapMutations(['setUserInfo']),
    ...mapActions(['analyseRouter']),
    /**'
     * @description 修改密码
     * */
    modifyPassword() {
      let {oldPassword, password, confirmPassword} = this.form
      const data = {old_password: oldPassword, password, re_password: confirmPassword, id: this.user_info.id}
      postApi2('/account/change-password', data).then(res => {
        this.$message.success({message: '修改密码成功！'})
        this.$utils.loginApi.clearLoginData()
        this.$router.replace('/login')
      })
    },

    changeMenu(index) {
      this.$store.commit('setRightMenuIndex', index)
    },
    /**
     * @description 切换角色
     */
    toggleRole() {
      this.loadingState = true
      if (this.radioRole == null) {
        this.loadingState = false
        return this.$message.warning('请选择角色');
      } else {
        this.$utils.loginApi.toggleRole(this.radioRole).then(res => {
          let {data, error} = res.data
          if (error.errorCode === 0) {
            this.dialogVisible = false
            let userInfo = this.userInfo
            userInfo.userinfo.role_name = data.role_name
            userInfo.userinfo.role_id = this.radioRole;
            userInfo.userinfo.current_role = this.radioRole;
            userInfo.school_arr = data.school_arr
            this.setUserInfo(userInfo)
            this.analyseRouter().then(res => {
              this.$router.push('/')
            })
          }
        })
      }
      this.loadingState = false
    },
    handleSelect(key) {
      let index
      for (let i = 0, len = config.length; i < len; i++) {
        let item = config[i]
        if (key === item.path) {
          index = i
          break
        }
      }
      this.$store.commit('setRightMenuIndex', index)
    },
    handleClickDropDown(idx) {
      if (!idx) {
        this.modifyPasswordVisible = true
      } else if (idx === 1) {
        this.$router.push('/personal/account')
      }
    },
    onMailboxSave() {
      if (this.isValidEmail(this.USER_INFO.userinfo.mailbox))
        this.$_axios2.get('api/teacher/account/mailbox', {params: {mailbox: this.USER_INFO.userinfo.mailbox}}, {logic: 1}).then(res => {
          this.$message.success('邮箱修改成功')
          sessionStorage.setItem('USER_INFO', JSON.stringify(this.USER_INFO))
          this.MailboxDialog = false
        })
      else this.$message.error('邮箱格式不正确')
    },
    isValidEmail(email) {
      const regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      return regex.test(email);
    },
    onDropDown(e) {
      if (e) this.$refs.dropdown.hide()
      switch (e) {
        case 0:
          this.$router.push('/personal/details')
          break
        case 1:
          this.dialogVisible = true
          break
        case 2:
          this.$confirm('退出登录？退出后需重新登录', '提示', {
            confirmButtonText: '退出当前登录账号',
            cancelButtonText: '取消',
            type: 'warning',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                instance.confirmButtonLoading = true
                // instance.confirmButtonText = '执行中...';
                this.$utils.loginApi.logout().then((res) => {
                  this.$utils.loginApi.clearLoginData()
                  this.$message.success({message: '退出登录成功', showClose: true})
                  this.$router.replace('/login')
                  instance.confirmButtonLoading = false
                }).catch(err => instance.confirmButtonLoading = false)
              }
              done()
            }
          })
          break
        case 3:
          this.modifyPasswordVisible = true
          break;
        case 4:
          let id = JSON.parse(sessionStorage.getItem('USER_INFO')).user_role[0].type
          if (id == '4') {
            this.$router.push('/settings/system/feedback/list')
          } else {
            this.feedbackShow = true
          }
          break;
        case 5:
          this.$router.push('/personal/account')
          break;
        case 6:
          this.USER_INFO = JSON.parse(sessionStorage.getItem('USER_INFO'))
          this.MailboxDialog = true
          break;
      }
    },

    getFeedbackConfig() {
      this.$_axios2('/api/feedback/problem/type').then(res => {
        let data = res.data.data
        this.feedbackTpeOptions = data.teacher
      })
    },
    clearDialog() {
      this.$refs.feedback.resetFields()
      this.$refs.upload.clearFiles();
      this.listImage = []
      this.isUploaded = false
    },

    handleRemove(file, fileList) {
      this.isUploaded = false
      const idx = this.listImage.findIndex(item => item.name === file.name)
      if (~idx) {
        this.listImage.splice(idx, 1)
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.uploadDialogShow = true;
    },

    handleExceed() {
      this.$message({
        type: 'warning',
        message: '所选文件个数超出最大上传数！',
        duration: 2000
      })
    },
    //图片上传前的回调
    imgUploadBefore(file) {

      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      // const isMP4 = file.type === 'audio/mp4' || file.type === 'video/mp4' || file.type === 'video/mpeg4';
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message({
          type: 'error',
          message: '上传图片只能是 jpg/png 格式!',
          duration: 2000
        })
      }
      if (!isLt2M) {
        this.$message({
          type: 'error',
          message: '上传图片大小不能超过 5MB!',
          duration: 2000
        })
      }
      return isJPG && isLt2M;
    },

    uploadImg(file) {
      let boo = this.imgUploadBefore(file.file)
      if (boo) {
        const loading = this.$loading({
          lock: true,
          text: '上传中',
          customClass: 'img-upload-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.$uploadToOSS_1(file.file, 1, 1).then(res => {
          this.listImage.push({
            name: file.file.name,
            url: res
          })

          if (this.listImage.length === 6) {
            this.isUploaded = true
          }
        }).finally(() => {
          loading.close()
        })
      }
    },

    feedbackSubmit() {
      this.$refs.feedback.validate().then(res => {
        let params = {...this.feedbackForm}
        if (params.image) params.image = params.image.slice(0, -1)
        this.$_axios2.post('/api/feedback/problem/teacher', params).then(res => {
          if (res.data.status === 0) {
            this.feedbackShow = false;
            this.feedbackSuccessShow = true
            this.clearDialog()
          }
        })
      }).catch(err => {
        // this.$message.warning("请输入完整的测评信息")
      })
    },

    getConfig() {
      this.$_register('/api/recruit/enroll/basic', {params: {address_code: '000000'}}).then(res => {
        if (res.data.status === 0) {
          this.Config = res.data.data;
          tools.setSession('gaokaoConfig', res.data.data)
        }
      })
    },

  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_setting.scss";

::v-deep .el-dialog {
  width: 644rem !important;
}

::v-deep .is-uploaded .el-upload {
  display: none;
}

.el-menu.el-menu--horizontal {
  border-bottom: none;
  padding-top: 10rem;
  // width:1000rem;
}

.el-menu--horizontal > .el-menu-item {
  border-bottom: 4rem solid transparent;
}

.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 4rem solid #1d2088;
}

::v-deep .el-menu--horizontal > .el-menu-item {
  font-weight: bold;
  font-size: 20rem;
  margin: 0 calc(100vw * 0.0125833);
  color: $menu;
}

.right-header {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 2000;

  .top-menu {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  .user {
    display: flex;
    align-items: center;

    .avatar {
      margin-right: 16rem;
      width: 50rem;
      height: 50rem;
      border: 1rem solid #1d2088;
      border-radius: 50%;
    }

    .info {
      font-size: 12rem;
      color: #7f7f7f;
      margin-right: 40rem;

      .name {
        color: #000000;
        font-weight: bold;
        font-size: 14rem;
      }
    }
  }

}

.el-dialog__wrapper {
  .roleTab {
    margin-left: 20rem;
  }

  .role-radio {
    margin-top: 20rem;
  }
}

.feedback_type,
::v-deep.feedback_type .el-input {
  width: 100% !important;
}

.personalization {
  display: block;
}

</style>
<style>
.t_title_top {
  font-weight: 500;
  font-size: 18px;
  color: #1E3AA2;
}

.el-dialog__header {
  border-bottom: 1px solid #F2F2F2;
}

</style>
